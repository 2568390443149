import React from "react";

import imgD from "../../assets/image/inner-page/webp/swipe-navigation.webp";
import dlogo from "../../assets/image/D-white.png"
import { QRCode } from 'react-qrcode-logo';
const CTA  = ({ className, ...rest }) => {

  return (
    <div className={className} {...rest}>
      <div className="pt-14 pt-md-19 pt-lg-10 pb-15 pb-md-18 pb-lg-25 mb-lg-1">
      <div className="container">
        <div className="row justify-content-center mb-10 mt-lg-0">
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
            <h3 className="font-size-9 mb-10 mt-10">Pioneering Swipe Navigation</h3>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>Digma is the first to produce the capability of swiping through a video to navigate.</p>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>This one-handed mode of navigation refelects the way people are now used to exploring content on mobile devices.</p>
               <p className="d-none d-md-block font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>Have a go yourself by scanning the QR code below.</p>
                <div className="qr d-none d-md-block">
                  <QRCode value="https://demo.digma.io/?projectId=31DEC523-F19A-4B13-A73C-0E6D5CFEC249&digmaautoplay=true" quietZone="10" logoOpacity="1" qrStyle="dots" logoWidth="75" logoHeight="75" logoImage={dlogo} size="250" fgColor="#258aff" bgColor="#F7F9FC"  />
                </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11 text-center"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <img src={imgD} alt="Swipe Navigation Mobile" className="w-50 box-shadow rounded-5 mobilefull" />
            </div>          
        </div>
      </div>
    </div>
    </div>
  );
};

export default CTA;
